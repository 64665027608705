
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {getModule} from 'vuex-module-decorators';
    import {store} from '@/store';
    import {namespace} from 'vuex-class';
    import {DateTime, ToISOTimeOptions} from 'luxon';
    import {localStorageService} from '@/storage/localstorage.service';

    import {
      BookingModule,
      SnackbarModule,
      AnimalModule,
    } from '@/store/modules';

    import {
        IBookingProcessing,
        IClient,
        IAnimal,
        IUser,
        ISantevetGetQuotationParams,
        IVeterinarian,
        IAgenda,
    } from '@/types';

    const accountNamespace = namespace('account');
    const bookingNamespace = namespace('booking');
    const animalNamespace = namespace('animal');

    import {AccountModule} from '@/store/modules';

    @Component<BookingInsurance>({
        components: {
        },
        beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
            const accountModule = getModule(AccountModule, store);
            const bookingProcessing = store.getters['booking/bookingProcess'];

            if (accountModule.isLoggedIn) {
                if (!bookingProcessing.animal) {
                    next({name: 'step-animal', query: to.query});
                }
                next();
                
            } else {
                if (!bookingProcessing.animal) {
                    next({name: 'step-animal', query: to.query});
                } else {
                    next({name: 'step-logger', query: to.query});
                }
            }
        },
    })
    export default class BookingInsurance extends Vue {
        // public optinInsurance: boolean = false;
        public optinInsuranceAgree: boolean|null = null;
        public submitting: boolean = false;
        public time: DateTime|null = null;
        public bookingError: boolean = false;
        public isBlacklisted: string|null = null;
        public dialogBlacklist: boolean = false;
        public animalsCanInsure: IAnimal[] = [];
        public animalsLoaded: boolean = false;
        public selectedAnimal: IAnimal | null = null;
        public veterinarian: IVeterinarian|null = null;
        public loadingUrl: boolean = false;
        public sendedInsurance: boolean = false;
        public handleOptinValidForm: boolean = false;

        @accountNamespace.Getter('loggedClient')
        public client!: IClient;

        @bookingNamespace.Getter('bookingProcess')
        public bookingProcess!: IBookingProcessing;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        @animalNamespace.Getter('animalsList')
        public animals!: IAnimal[];

        public canInsureAnimal(animalId: string) {
            return new Promise<IAnimal[]>((resolve, reject) => {
                getModule(AnimalModule, this.$store)
                    .santevetInsuranceRates({clientId: this.client.id as string, animalId})
                    .then( (data) => {
                        if (!data.rates)return;

                        const findAnimal = this.animals.find((animal: IAnimal) => animalId === animal.id) as IAnimal;
                        this.animalsCanInsure.push(findAnimal);
                        resolve(this.animalsCanInsure);
                    },
                    );
                },
            );
        }

        public fetchAnimals() {
            if (this.loggedUser.account?.type !== 'client') {
                this.bookAppointment();
            } else {
                getModule(AnimalModule, this.$store)
                .fetchAnimals(this.client.id)
                    .then(() => {
                        if(!this.bookingProcess.animal) return;
    
                        this.canInsureAnimal(this.bookingProcess.animal.id).then((e) => {
                            this.animalsLoaded = true;
                            if (e.length === 1) {
                                const findAnimal = this.animals.find(
                                (anml: IAnimal) => this.animalsCanInsure[0].id === anml.id) as IAnimal;
                                this.selectedAnimal = findAnimal;
                            }
                        });
                        this.animalsLoaded = true;
                    },
                );
            }
        }

        public sendLeadInusrance() {
            //envoi du mail d'assurance
            let params: ISantevetGetQuotationParams = {};

            params.clientId = this.client.id;
            params.animalId = this.bookingProcess.animal?.id;
            params.cellPhone = this.client.cell_phone;
            params.city = this.veterinarian?.agenda?.organization?.address?.city;
            params.zip = this.veterinarian?.agenda?.organization?.address?.postal_code;

            this.loadingUrl = true;

            getModule(AnimalModule, this.$store)
            .santevetGetQuotation(params)
                .then((data) => {
                    if(data.url) {
                        getModule(BookingModule, this.$store).handlerBookingProcess({santevet_quotation: data.url});
                    }
                    this.loadingUrl = false;
                    this.sendedInsurance=true;
            });

        }

        public bookAppointment() {
            if (this.submitting) {
                return;
            }

            if (this.$route.query.time) {
                this.time = DateTime.fromISO(this.$route.query.time as string);
            }

            if (
                !this.client ||
                !this.bookingProcess.animal ||
                !this.bookingProcess.reason ||
                !this.bookingProcess.agenda ||
                !this.bookingProcess.public_comment ||
                !this.time
            ) {
              getModule(SnackbarModule, this.$store)
                  .displayError(
                      `Une erreur est survenue, veuillez réessayer<br>
                       Si le problème persiste, merci de nous contacter`,
                  )
              ;

              return;
            }
            this.submitting = true;

            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
            };
            if(this.sendedInsurance === false && this.optinInsuranceAgree === true) {
                this.sendLeadInusrance();
            }

            getModule(BookingModule, this.$store)
                .createBooking({
                    client_id: this.client.id,
                    animal_id: this.bookingProcess.animal.id,
                    reason_id: this.bookingProcess.reason.id,
                    agenda_id: this.bookingProcess.agenda.id as string,
                    initial_comment: this.bookingProcess.public_comment as string,
                    start: this.time.toUTC().toISO(formatOpts) as string,
                    organization_instructions: this.bookingProcess.instructions_valid,
                    organization_rules: this.bookingProcess.rules_valid,
                    referent_organization_id:
                        this.loggedUser.client?.organization_id ? this.loggedUser.client?.organization_id : null,
                })
                .then(() => {
                    this.submitting = false;
                    this.$router.push({
                        name: 'step-summary',
                        query: this.$route.query,
                    });
                })
                .catch((error) => {
                    this.submitting = false;
                    
                    localStorageService.remove('booking_quotation');
                    if (error.response.data.error === 'blacklisted') {
                        this.bookingError = true;
                        this.dialogBlacklist = true;
                    }

                    if (error.response.data.reason  === 'slot_unavailable') {
                        getModule(SnackbarModule, this.$store)
                            .displayError(
                                `Ce créneau n'est plus disponible<br>
                                Merci de prendre rendez-vous sur un autre créneau`,
                            )
                        ;
                    } else {
                        getModule(SnackbarModule, this.$store)
                            .displayError(
                                `Une erreur est survenue, veuillez réessayer<br>
                                Si le problème persiste, merci de nous contacter`,
                            )
                        ;
                    }
                })
            ;
        }

        public veterinarianInfos() {
            if (!this.bookingProcess.agenda?.id) {
            return;
            }
            this.$api.agenda.getById(this.bookingProcess.agenda?.id as string)
            .then((veterinarian: IVeterinarian) => {
                this.veterinarian = veterinarian;
                const agenda =  veterinarian.agendas?.find((val: IAgenda) => {
                    return val.id === this.$route.query.agenda_id;
                });
                if (this.veterinarian) this.veterinarian.agenda = agenda;
            });
        }

        public mounted() {
            if (this.client) {
                this.fetchAnimals();
                this.veterinarianInfos();
            }
        }
    }
